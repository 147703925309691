// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-solutions-architecture-js": () => import("./../../../src/pages/solutions/architecture.js" /* webpackChunkName: "component---src-pages-solutions-architecture-js" */),
  "component---src-pages-solutions-costoptimization-js": () => import("./../../../src/pages/solutions/costoptimization.js" /* webpackChunkName: "component---src-pages-solutions-costoptimization-js" */),
  "component---src-pages-solutions-devops-js": () => import("./../../../src/pages/solutions/devops.js" /* webpackChunkName: "component---src-pages-solutions-devops-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-migration-js": () => import("./../../../src/pages/solutions/migration.js" /* webpackChunkName: "component---src-pages-solutions-migration-js" */),
  "component---src-pages-solutions-security-js": () => import("./../../../src/pages/solutions/security.js" /* webpackChunkName: "component---src-pages-solutions-security-js" */)
}

